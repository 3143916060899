import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Feature from './components/Feature'
import WhyChooseUs from './components/WhyChooseUs'
import InfoSection from './components/InfoSection'
import { Footer } from './components/Footer'
import Newsletter from './components/NewsLetter'

const App = () => {
  return (
    <>
    <div className="bg-[url('./assets/images/bg-hero.jpg')]">
      <Navbar />
      <hr/>
      <Hero />
      </div>
      <WhyChooseUs id="1" />
      <InfoSection />
      <Feature />
      <Newsletter/>
      <Footer/>
    </>
  )
}

export default App
