import {
    Popover,
    PopoverHandler,
    PopoverContent,
  } from "@material-tailwind/react";
   
  export function PopoverCustom() {
    return (
      <Popover
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 },
        }}
      >
        <PopoverHandler>
           <button className='text-black bg-[#00df9a] p-2 w-[200px] rounded-md font-medium'>Get Started</button>
        </PopoverHandler>
        <PopoverContent className="text-xl">
            contact.digitalfeathers.co.in
        </PopoverContent>
      </Popover>
    );
  }