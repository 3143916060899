import { Typography } from "@material-tailwind/react";
import React from "react";

const Navbar = () => {
  return (
    <div className="text-white flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4">
      <h1 className="w-full text-2xl font-bold text-[#00df9a]">
        Digital Feathers{" "}
      </h1>
      <ul className="flex">
        {/* <li className='p-4 cursor-pointer'>About</li>
                <li className='p-4 cursor-pointer'>Service</li> */}
        <li className="p-4 cursor-pointer">
          <Typography
            as="a"
            href="mailto:contact.digitalfeathers@gmail.com"
            className="opacity-80 transition-opacity hover:opacity-100 flex gap-2 text-lg font-bold "

          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6 font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg> <span className="hidden md:block">contact.digitalfeathers@gmail.com</span>
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
