import React from 'react'
import { useEffect, useRef } from "react";
import Typed from "typed.js";
import { PopoverCustom } from './Popover';


const Hero = () => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [
                "📈 Digital Marketing!",
                "🎯 SEO!",
                "🌐 Web Development!",
                "💪And More!"
            ], // Strings to display
            // Speed settings, try diffrent values untill you get good results
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 100,
            smartBackspace: true,
            loop: true,
            showCursor: true,
            // cursorChar: "!",
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, []);
    return (
        <div className='text-white'>
            <div className='max-w-[800px] mt-[96px] w-full  mx-auto text-center md:h-[80vh] sm:h-[70vh] h-[50vh]'>
                <p className='text-[#00df9a] font-bold text-xs md:text-xl pt-2'>GROWING WITH DIGITAL FEATHERS</p>
                <h1 className='md:text-5xl sm:text-4xl text-2xl font-bold md:py-2'>Unlock Your Digital Success</h1>
                <p className='font-bold md:text-2xl sm:text-lg py-2'>Leading digital marketing agency specializing <br/> in 
                    <span ref={el} className='text-[#00df9a] pl-2'></span>
                </p>      {/* Element to display typing strings */}
                <div className='text-gray-500 font-bold md:text-2xl text-xl p-8'>
                We have the expertise and innovative strategies to help your business thrive in the digital landscape.
                </div>
                <PopoverCustom/>
                {/* <button className='text-black bg-[#00df9a] p-2 w-[200px] rounded-md font-medium'>Get Started</button> */}
            </div>
        </div>
    )
}

export default Hero