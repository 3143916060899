import React from 'react'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
const features = [
    {
        name: 'Web Development',
        description:
            'Captivating websites that engage your audience and reflect your brand identity.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'SEO (Search Engine Optimization)',
        description:
            'Boost your websites visibility and attract organic traffic',
        icon: LockClosedIcon,
    },
    {
        name: 'Digital Marketing',
        description:
            'Tailored strategies to enhance your online presence and drive conversions.',
        icon: ArrowPathIcon,
    },
    {
        name: 'And More',
        description:
            'We offer a range of additional services to ensure your digital success!',
        icon: FingerPrintIcon,
    },
]

const Feature = () => {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600"> Our Services</h2>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        Everything you need to develop your Idea
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    📢 Join our digital community and stay updated with the latest trends and insights in web development, SEO, digital marketing, and more. Let's unlock the full potential of your online presence together! 💼🌍💥
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Feature

